import React from "react";
import PageLoader from "../../components/PageLoader";
import Pagination from "../../components/Pagination";
import Button from "../../components/ui/Button";
import Row from "../../components/ui/Row";
import { useSongsQuery } from "../../data/songs/songs.query";
import { EditMusicForm, AddMusicForm, MusicListContainer } from "./components";
import { SharedModal } from "../../shared/Modal";
import { ToastContainer } from "react-toastify";
export default function Music() {
  const [page, setPage] = React.useState(1);
  const [offset] = React.useState(10);
  const { data, isLoading, refetch }: any = useSongsQuery({ page, offset });

  const [selectedItem, setSelectedItem] = React.useState(null);

  const [isOpenEditModal, setIsOpenEditModal] = React.useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = React.useState(false);

  const onToggleEditSong = () => {
    setIsOpenAddModal(false);
    setIsOpenEditModal(!isOpenEditModal);
  }

  const onToggleAddSong = () => {
    setIsOpenAddModal(!isOpenAddModal);
    setIsOpenEditModal(false);
  }

  const handleChangePage = (p: number) => {
    setPage(p);
  };
  React.useEffect(() => {
    refetch();
  }, [page]);
  React.useEffect(() => { }, [isLoading, data]);

  if (isLoading || !data) return <PageLoader />;

  const onSelectItem = (item: any) => {
    if (item !== undefined) {
      setSelectedItem(item);
      onToggleEditSong();
    }
  }

  return (
    <div className="bg-black">
      <div className="px-5">
        <Row className="justify-between items-center">
          <div className="flex flex-col ">
            <span className="text-primary text-2xl">Songs</span>
            <span className="text-white text-xs">
              {data?.data?.count} song
            </span>
          </div>
          <div>
            <Button
              variant="normal"
              size="medium"
              className="rounded-full"
              onClick={onToggleAddSong}
            >
              Add music
            </Button>
          </div>
        </Row>
      </div>
      <div className="">
        <MusicListContainer data={data?.data?.items}
          refetch={refetch as any}
          selectItem={onSelectItem}
        />
        <Row className="justify-end mr-20 mt-5">
          <Pagination
            total={data?.data?.count}
            limit={offset}
            skip={page}
            onChange={handleChangePage}
          />
        </Row>
      </div>
      <SharedModal
        isOpen={isOpenEditModal}
        title={'Edit music'}
        onToggle={onToggleEditSong}
      >
        <EditMusicForm
          toggleModal={onToggleEditSong}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          refetch={refetch}
        />
      </SharedModal>
      <SharedModal
        isOpen={isOpenAddModal}
        title={'Add music'}
        onToggle={onToggleAddSong}
      >
        <AddMusicForm
          toggleModal={onToggleAddSong}
          selectedItem={selectedItem}
          onItemStateChange={refetch}
        />
      </SharedModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        theme="dark"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
