import React from "react";
import Button from "../ui/Button";

interface Props {
  isOpen: boolean;
  toogleModal: () => void;
  modalTitle?: string;
  children: React.ReactElement;
}
export default function ModalPlaylist({
  isOpen,
  toogleModal,
  modalTitle,
  children,
}: Props) {
  return isOpen ? (
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      // onClick={toogleModal}
    >
      <div className="relative w-full my-6 mx-auto max-w-3xl ">
        {/*content*/}
        <div className="rounded-2xl shadow-lg relative flex flex-col w-full bg-BlackGray outline-none focus:outline-none border border-solid border-gray-500">
          {/*header*/}
          <div className="flex items-center justify-between p-5  rounded-t">
            <h3 className="text-xl font-semibold text-primary">{modalTitle}</h3>
            <button
              className="p-1 ml-auto  text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={toogleModal}
            >
              <span className="bg-transparent text-white text-3xl h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">{children}</div>

          {/*footer*/}
        </div>
      </div>
    </div>
  ) : null;
}
