import { Settings, Trash } from "react-feather";
import Row from "../../../components/ui/Row";
interface CProps {
  deleteSong: (songId: number) => void;
  selectItem: (item: any) => void;
}

const columns = ({ deleteSong, selectItem }: CProps) => [
  {
    Header: "Name",
    accessor: "name", // accessor is the "key" in the data
  },
  {
    Header: "Artists",
    accessor: "artist", // accessor is the "key" in the data,

    Cell: ({ row: { original } }: any) => {
      return (
        <div>
          <span className="text-white"> {original?.artist?.name} </span>
        </div>
      );
    },
  },
  {
    Header: "duration (s)",
    accessor: "duration", // accessor is the "key" in the data
  },
  {
    Header: "",
    accessor: "test",
    Cell: ({ row: { original } }: any) => (
      <Row className="space-x-4">
        <div style={{cursor:"pointer"}} onClick={() => deleteSong(original?.id)}>
          <Trash color="white" size={20} />
        </div>
        <div
         style={{ cursor: "pointer" }}
         className="" 
         onClick={() => selectItem(original)}>
          <Settings color="white" size={20} />
        </div>
      </Row>
    ),
  },
];
export default columns;
