import React from 'react'
import Button from '../components/ui/Button';
import { SharedModal } from './Modal'
interface Props {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({ title = 'Are you sure ?', isOpen, onConfirm , onToggle}: Props) => {
  return <>
    <SharedModal
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <div>
        <div className="flex justify-center items-center">
          <span className="text-xl text-white">{title}</span>
        </div>
        <div className="space-x-5 mt-10 text-center">
          <Button onClick={onToggle} variant="outline" className="bg-black-500 text-white">
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </div>
      </div>
    </SharedModal>
  </>
};