import http from "../config/http";

export default class Base<C, U> {
  http = async <T>(
    url: string,
    type: string,
    variables: T | string | null = null,
    options?: any
  ) => {
    // console.log({ type, url, variables, options });
    return (http as any)[type](url, variables, options);
  };
  all = async (url: string) => {
    return this.http(url, "get");
  };

  find = async (url: string) => {
    return this.http(url, "get");
  };

  create = async (url: string, variables: C, options?: any) => {
    return this.http<C>(url, "post", variables, options);
  };

  update = async (url: string, variables: U, options?: any) => {
    return this.http<U>(url, "put", variables, options);
  };

  delete = async (url: string) => {
    return this.http(url, "delete");
  };
}
