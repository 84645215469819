import React, { useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Row from "../../../components/ui/Row";
import http from "../../../config/http";
import genre from "../../../repositories/genre";

interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}
export default function FormGenre({
  selectedItem,
  setSelectedItem,
  toggleModal,
  refetch,
}: Props) {
  const [exist, setExist] = useState<boolean>(false);

  const addGenre = async (payload: any) => {
    try {
      await http.post(`/genres`, payload)
      toggleModal();
      refetch(); 
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  const updateGenre = async (payload: any) => {
    try {
      await genre.updateGenre(selectedItem?.id, payload)
      toggleModal();
      refetch(); 
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ name: selectedItem?.name }}
        onSubmit={async (values) => {
          if (!selectedItem) await addGenre(values);
          else await updateGenre(values);
        }}
        validate={(values) => {
          setExist(false)
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Genre is required";
          } else if (!/^[A-Za-z]+[A-Za-z\s\-]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <Row>
              <div className="w-full">
                <Input
                  name="name"
                  placeholder="Type a genre"
                  label="Name"
                  className=""
                  variant="normal"
                  maxLength={16}
                  value={values?.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="w-[320px] mt-2">
                  {errors.name && (
                    <div className="text-red-500">
                      {errors.name && touched.name && errors.name}
                    </div>
                  )}
                </div>
                {exist && (
                  <span className="text-red-500">Genre already exists</span>
                )}
              </div>
            </Row>
            <div className="flex items-center justify-center pt-6 rounded-b">
              <Button
                type="submit"
                variant="normal"
                className="rounded-full w-40 h-10"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <small className="text-black">Confirm</small>
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
