import React from "react";
import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { Artist } from "../../../types/generalType";
import ArtistRepositories from "../../../repositories/artist";
import withConfirmation from "../../../hoc/withConfimation";

interface Props {
  data: Artist[];
  showConfirmModal: any;
  refetch: any;
  selectItem: any;
}

const ArtisteListContainer = ({ data, showConfirmModal, refetch, selectItem }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteArtist(artistId: number) {
    showConfirmModal({
      title: "Are you sure to delete this artist ?",
      confirmFunction: () => {
        ArtistRepositories.deleteArtist(artistId).then(() => {
          refetch();
        });
      },
    });
  }
  return <DataTable columns={columns({ deleteArtist, selectItem })} data={data} />;
};
export default withConfirmation(ArtisteListContainer);
