import React, { useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Row from "../../../components/ui/Row";
import http from "../../../config/http";
import mood from "../../../repositories/mood";
interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}
export default function FormMood({
  selectedItem,
  setSelectedItem,
  toggleModal,
  refetch,
}: Props) {
  const [exist, setExist] = useState();

  const addMood = async (payload: any) => {
    await http
      .post(`/moods`, payload)
      .then((res) => {
        toggleModal();
        refetch();
      })
      .catch((err) => {
        setExist(err)
        console.log({ err })
      });
  };
  const updateMood = async (payload: any) => {
    await mood.updateMood(selectedItem?.id, payload).then((httpResponse) => {
      toggleModal();
      refetch();
    })
      .catch((err) => {
        setExist(err)
        console.log({ err })
      });
  };

  return (
    <div>
      <Formik
        initialValues={{ name: selectedItem?.name }}
        validate={(values) => {
          setExist(undefined)
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Mood is required";
          } else if (!/^[A-Za-z]+[A-Za-z\s]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          if (!selectedItem) await addMood(values);
          else await updateMood(values);
        }}
      >
        {(
          {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }
        ) => (<>
          <Row>
            <div className="w-full">
              <Input
                name="name"
                placeholder="Write the name"
                label="Name"
                className=""
                variant="normal"
                value={values?.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className="w-[320px] mt-2">
                {errors.name && (
                  <div className="text-red-500">
                    {errors.name && touched.name && errors.name}
                  </div>
                )}
                {exist && (
                  <div className="text-red-500">Mood already exists</div>
                )}
              </div>
            </div>
          </Row>
          <div className="flex items-center justify-center pt-6 rounded-b">
            <Button
              type="submit"
              variant="normal"
              className="rounded-full w-40 h-10"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              <small className="text-black">Confirm</small>
            </Button>
          </div>
        </>)}
      </Formik>
    </div>
  );
}
