import { ENDPOINTS } from "../../config/constants";
import GenreRepository from "../../repositories/genre";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchGenre = async ({ offset = 10, page = 1 }: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await GenreRepository.fetchGenre(`${ENDPOINTS.GENRES}?${params}`);
};
export const useGenresQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.GENRES], () => fetchGenre({ offset, page }));
};
