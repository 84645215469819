import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { IPlaylist } from "../../../types/generalType";
import PlaylistRepository from "../../../repositories/playlist";
import withConfirmation from "../../../hoc/withConfimation";
interface Props {
  data: IPlaylist[];
  showConfirmModal: any;
  refetch: () => void;
  selectItem: any;

}
const PlaylistContainer = ({ 
  data, 
  showConfirmModal, 
  refetch,
  selectItem,
 }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">empty list</span>;
  }
  function deletePlaylist(playlistId: number) {
    showConfirmModal({
      title: "Are you sure to delete this playlist ?",
      confirmFunction: () => {
        PlaylistRepository.deletePlaylist(playlistId).then(() => {
          refetch();
        });
      },
    });
  }
  return (
    <DataTable columns={columns({ deletePlaylist, selectItem })} data={data} />
  );
};
export default withConfirmation(PlaylistContainer);
