import React from "react";
import Modal from "../../components/Modal";
import PageLoader from "../../components/PageLoader";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import Button from "../../components/ui/Button";
import Row from "../../components/ui/Row";
import Color from "../../config/Color";
import { useBuisnessQuery } from "../../data/buisness/buisness.query";
import BuisnessListContainer from "./components/BuisnessListContainer";
import FormBuisness from "./components/FormBuisness";
export default function Buisness() {
  const [page, setPage] = React.useState(1);
  const [offset] = React.useState(10);
  const { data, isLoading, refetch }: any = useBuisnessQuery({ page, offset });
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const handleChangePage = (p: number) => {
    setPage(p);
  };
  React.useEffect(() => {
    refetch();
  }, [page]);
  React.useEffect(() => {}, [isLoading, data]);
  const toogleModal = () => {
    setIsOpenModal(!isOpenModal);
    setSelectedItem(null);
  };
  if (isLoading || !data) return <PageLoader />;
  function selectItem(item: any = null) {
    toogleModal();
    if (item) setSelectedItem(item);
  }
  return (
    <div className="bg-black">
      <div className="p-5">
        <Row className="justify-between items-center">
          <div className="flex flex-col ">
            <span className="text-primary text-2xl">Business</span>
            <span className="text-white text-xs">
              {data?.data?.count} Business
            </span>
          </div>
          <div>
            <Button
              variant="normal"
              size="medium"
              className="rounded-full"
              onClick={toogleModal}
            >
              Add Buisness
            </Button>
          </div>
        </Row>
      </div>
      <div className="">
        <BuisnessListContainer
         data={data?.data?.items}
          refetch={refetch} 
          selectItem={selectItem}
          />
        <Row className="justify-end mr-20 mt-5">
          <Pagination
            total={data?.data?.count}
            limit={offset}
            skip={page}
            onChange={handleChangePage}
          />
        </Row>
      </div>
      <Modal
        isOpen={isOpenModal}
        toogleModal={toogleModal}
        modalTitle={selectedItem ? "Edit Business" : "Add Business"}
        fullwidth={false}
      >
        <div className="my-4 text-blueGray-500 text-lg leading-relaxed">
          <FormBuisness 
           toggleModal={toogleModal}
           selectedItem={selectedItem}
           setSelectedItem={setSelectedItem}
           refetch={refetch}
          />
        </div>
      </Modal>
    </div>
  );
}
