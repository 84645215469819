import React from "react";
import { useDropzone } from "react-dropzone";
import { Image } from "react-feather";
import Color from "../../config/Color";

interface Props {
  files: any;
  className: any;
  title?: string;
}
export default function Dropzoneplaylist(props: Props) {
  const [file, setFile] = React.useState<any>(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    // Do something with the files
    props.files(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop, 
    accept: ['image/png', 'image/jpg', 'image/jpeg'], 
    multiple: false,
  });
  return (
    <div {...getRootProps()} className={props.className}>
      <div className="bg-[#ffffff26] rounded-md flex justify-center items-center flex-col border border-[#ddd]">
        <input {...getInputProps()} />

        <div className="flex justify-center flex-col text-white items-center h-16 text-sm">
          <Image color={Color.primary} size={24} />
          {isDragActive ? (
            <p>{props.title ?? 'Drag your file here'}</p>
          ) : file ? (
            <p>{file.name}</p>
          ) : (
            <span className="text-xs font-bold">
              {props.title ?? 'Drag your file here'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
