import React, { useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Dropzone from "../../../components/Dropzone";
import Row from "../../../components/ui/Row";
import musicSvc from "../../../repositories/music";
import { useArtistsQuery } from "../../../data/artist/artist.query";
import { StaticLoader } from "./Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Song {
  name: string;
  artist: any;
  file: string;
}

interface Props {
  selectedItem: any;
  toggleModal: () => void;
  onItemStateChange?: () => void;
}

export default function AddMusicForm(props: Props) {

  const { data: dataArtists, isLoading } = useArtistsQuery({
    page: 1,
    offset: 999,
  });

  const renderArtistOptions = (data: any) => {
    return data?.items?.map((artist: any) => ({
      label: artist?.name,
      value: artist?.id,
    }));
  };

  const [uploads, setUploads] = useState<number>(0);

  React.useEffect(() => {
  }, [dataArtists, isLoading, uploads]);


  const prepareSongs = (files: any[], onSetSongs: (songs: Song[]) => void) => {
    const artists = renderArtistOptions(dataArtists?.data);
    const songList = files.map((file) => {
      const filename = file.name.replace(/\.[^/.]+$/, "")
      return {
        name: filename,
        file: file,
        artist: artists[0],
      } as Song
    })
    onSetSongs(songList);
  }

  const uploadSongs = async (songs: Song[], onDone: (i: number) => void, onError: (i: number) => void) => {
    let index = 1;
    for await (const song of songs) {
      let formData = new FormData();

      let songData = {
        name: song.name,
        artistId: song.artist.value,
      }
      const data = new Blob([JSON.stringify(songData)], {
        type: "application/json",
      });

      formData.append("song", data);
      formData.append("file", song.file);

      try {
        const res = await musicSvc.createMusic("/songs", formData as any)
        onDone?.(index);
        console.log(res)
      } catch (err) {
        onError(index - 1);
        console.log(err)
      }
      index++;
    }
  };

  return (
    <>
      <Formik
        initialValues={{ songs: Array<Song>(), errorNames: Array<string>(), errorArtists: Array<string>() }}
        validate={({ songs }) => {
          const errors = {
            errorNames: Array<string>(),
            errorArtists: Array<string>(),
          };
          for (let index = 0; index < songs.length; index++) {
            const song = songs[index];
            if (!song.artist || !song.name || song.name === '') {
              if (!song.artist) {
                errors.errorArtists.push(index.toString())
              }
              if (!song.name || song.name === '') {
                errors.errorNames.push(index.toString())
              }
            }
          }

          if (errors.errorArtists.length === 0 && errors.errorNames.length === 0) {
            console.log('No errors');
            return {}
          }
          console.log('With errors', errors);
          return errors
        }}
        onSubmit={async (values: any) => {
          await uploadSongs(values.songs,
            (i: number) => {
              setUploads(i);
            },
            (index: number) => {
              console.log('index', values.songs[index].name);
              toast.warn(`Failed to upload '${ values.songs[index].name}'`);
            },
          );
          props.onItemStateChange && props.onItemStateChange();
          props.toggleModal();
          return;
        }}
      >
        {({
          errors,
          values,
          setValues,
          handleSubmit,
          isSubmitting,
        }) => {
          const hasSongs = values.songs.length !== 0;
          return (
            <StaticLoader loading={isLoading}>
              <Form>
                <Row className="justify-center">
                  <div className="lg:mx-2 md:mx-2 center w-auto">
                    {!hasSongs && (<>
                      <Dropzone files={(values: any[]) => {
                        prepareSongs(values, (values) => setValues({ songs: values, errorArtists: [], errorNames: [], }));
                      }} />
                    </>)}
                  </div>
                </Row>
                {hasSongs && (<Row>
                  <div className="flex inline items-center justify-between w-full px-6 text-white font-['Poppins']">
                    <label className="w-full">NAME</label>
                    <label className="mx-2 w-96">ARTIST</label>
                  </div>
                </Row>)}
                {hasSongs && (<div className="h-96 overflow-auto">
                  {values.songs.map((song, i) => {
                    return (
                      <Row key={i}>
                        <div className="flex inline items-center justify-between w-full px-6">
                          <Input
                            name="name"
                            placeholder="Write the name"
                            className="my-2 w-full"
                            variant="normal"
                            defaultValue={song.name}
                            onChange={(value) => {
                              const { songs } = values;
                              songs[i].name = value.target.value;
                              setValues({ ...values, songs })
                            }}
                            disabled={isSubmitting}
                            readOnly={isSubmitting}
                          />
                          <Select
                            name="artist"
                            className="bg-BlackGray focus:bg-black text-black rounded mt-1 ml-2 w-64"
                            placeholder="Artist"
                            onChange={(artist: any) => {
                              const { songs } = values
                              songs[i].artist = artist;
                              setValues({ ...values, songs })
                            }}
                            options={renderArtistOptions(dataArtists?.data)}
                            value={song.artist}
                            defaultValue={song.artist}
                            styles={customStyles}
                            inputClassName="w-64"
                            disabled={isSubmitting}
                            readOnly={isSubmitting}
                          />
                        </div>
                        <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 px-6 text-white font-['Poppins']">
                          <p>{errors.errorNames?.includes(i.toString()) && 'Please add a name'}</p>
                          <p>{errors.errorArtists?.includes(i.toString()) && 'Please add an artist'}</p>
                        </span>
                      </Row>)
                  }
                  )}
                </div>)}
                <div className="flex items-center justify-center pt-6 rounded-b">
                  {hasSongs && (!isSubmitting
                    ? (
                      <div className="relative inline-flex">
                        <Button
                          variant="normal"
                          type="button"
                          className="w-40"
                          loading={isSubmitting}
                          onClick={handleSubmit}
                        >
                          <small className="text-black">Confirm</small>
                        </Button>
                      </div>
                    )
                    : (
                      <div className="flex items-center justify-center">
                        <button type="button" className="inline-flex justify-center w-48 h-12 items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-black bg-primary hover:bg-primary transition ease-in-out duration-150 cursor-not-allowed">
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Uploading ({uploads}/{values.songs.length})
                        </button>
                      </div>
                    ))}
                </div>
              </Form>
            </StaticLoader>
          )
        }}
      </Formik>
    </>
  );
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state?.isFocused ? 'grey' : state.selectProps.backgroundColor,
    color: state.isOptionSelected ? 'white' : undefined,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'white'
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transparent",
    "&:hover": { borderColor: "white" }, // border style on hover
    "&:focus": { borderColor: "white" }, // border style on hover
    border: "none", // default border color
    borderBottom: "1px solid white",
    borderRadius: "0px",
    boxShadow: "none", // no box-shadow
    width: 200,
    color: state.isOptionSelected ? 'white' : undefined
  })
};

const statusColor = {
  'none': 'transparent',
  'loading': '#f3bf59',
  'failure': 'red',
  'success': 'green',
}