import React from "react";
import * as Icon from "@iconscout/react-unicons";
import Color from "./Color";

const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    icon: <Icon.UilCreateDashboard size={32} color={Color.primary} />,
    path: "/",
  },
  {
    id: "add-music",
    title: "Songs",
    icon: <Icon.UilMusic size={32} color={Color.primary} />,
    path: "/music",
  },
  {
    id: "add-playlist",
    title: "Playlists",
    icon: <Icon.UilCreateDashboard size={32} color={Color.primary} />,
    path: "/playlist",
  },
  {
    id: "add-playlist-folders",
    title: "Folders Playlists",
    icon: <Icon.UilCreateDashboard size={32} color={Color.primary} />,
    path: "/playlists-folders",
  },
  {
    id: "add-genre",
    title: "Genre",
    icon: <Icon.UilCreateDashboard size={32} color={Color.primary} />,
    path: "/genre",
  },
  {
    id: "add-moods",
    title: "Moods",
    icon: <Icon.UilMusicNote size={32} color={Color.primary} />,
    path: "/moods",
  },
  {
    id: "add-artist",
    title: "Artists",
    icon: <Icon.UilUserSquare size={32} color={Color.primary} />,
    path: "/artists",
  },
  {
    id: "add-buisness",
    title: "Business",
    icon: <Icon.UilCreateDashboard size={32} color={Color.primary} />,
    path: "/business",
  }
];

export { navigationConfig };
