import { Settings, Trash } from "react-feather";
import Row from "../../../components/ui/Row";
interface CProps {
  deleteMood: (moodId: number) => void;
  selectItem: (item: any) => void;
}

const columns = ({ deleteMood, selectItem }: CProps) => [
  {
    Header: "Name",
    accessor: "name", // accessor is the "key" in the data
  },
  {
    Header: "",
    accessor: "test",
    Cell: ({ row: { original } }: any) => (
      <Row className="space-x-4 float-right">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => deleteMood(original?.id)}
        >
          <Trash color="white" size={20} />
        </div>
        <div
        style={{ cursor: "pointer" }}
        className=""
        onClick={() => selectItem(original)}>
          <Settings color="white" size={20} />
        </div>
      </Row>
    ),
  },
];
export default columns;
