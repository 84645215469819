import React from "react";
import { Formik } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Row from "../../../components/ui/Row";
import { useArtistsQuery } from "../../../data/artist/artist.query";
import music from "../../../repositories/music";
import { GroupBase, StylesConfig } from "react-select";
interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}

export default function EditMusicForm(props: Props) {
  const { data: dataArtists, isLoading } = useArtistsQuery({
    page: 1,
    offset: 999,
  });

  React.useEffect(() => { }, [dataArtists, isLoading]);
  const renderGenresOptions = (data: any) => {
    return data?.items?.map((artist: any) => ({
      label: artist?.name,
      value: artist?.id,
    }));
  };

  const updateMusic = async (payload: any) => {
    try {
      await music.updateMusic(props.selectedItem?.id, payload)
    } catch (error) {
      
    }
    props.toggleModal();
    props.refetch();
  };
  const getDefaultArtist = () => {
    const { selectedItem } = props;
    if (selectedItem) {
      return {
        label: selectedItem.artist?.name,
        value: selectedItem?.artist?.id,
      };
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ name: props.selectedItem?.name, artist: getDefaultArtist() }}
        onSubmit={async (values) => {
          await updateMusic({
            name: values.name,
            artistId: values.artist?.value,
          });
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Name is required";
          } else if (!/^[A-Za-z]+[A-Za-z\s\-]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          if (!values.artist || !values.artist.value || values.artist?.value === -1) {
            errors.artistId = 'Select an artist'
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Row>
              <div className="flex inline items-center justify-between w-full px-6">
                <Input
                  name="name"
                  placeholder="Song name"
                  label="Name"
                  className="m-2 w-full bg-BlackGray focus:bg-black text-black rounded "
                  variant="normal"
                  maxLength={24}
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Select
                  label="Artist"
                  name="artist"
                  className="bg-BlackGray focus:bg-black text-black rounded mt-1 mx-2 w-64"
                  placeholder="Artist"
                  onChange={(value) => setFieldValue('artist', value)}
                  onBlur={handleBlur}
                  defaultValue={values.artist}
                  options={renderGenresOptions(dataArtists?.data)}
                  styles={customStyles}
                  inputClassName="w-64 px-4 h-12 flex items-center w-full appearance-none transition duration-300 ease-in-out text-heading text-black focus:outline-none focus:ring-0"
                />
              </div>
            </Row>
            <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 px-6 text-white font-['Poppins']">
              {errors.name && (
                <p className="text-red-500">
                  {errors.name && touched.name && errors.name}
                </p>
              )}
              {errors.artist && (
                <p className="text-red-500 mt-2">
                  {errors.artist && touched.artist && errors.artist}
                </p>
              )}
            </span>
            <div className="flex items-center justify-center p-6 rounded-b">
              <Button
                variant="normal"
                className="rounded-full w-40 h-10"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <small className="text-black">Confirm</small>
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state?.isFocused ? 'grey' : state.selectProps.backgroundColor,
    color: state.isOptionSelected ? 'white' : undefined,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'white'
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transparent",
    "&:hover": { borderColor: "white" }, // border style on hover
    "&:focus": { borderColor: "white" }, // border style on hover
    border: "none", // default border color
    borderBottom: "1px solid white",
    borderRadius: "0px",
    boxShadow: "none", // no box-shadow
    width: 200,
    color: state.isOptionSelected ? 'white' : undefined
  })
};