import Base from "./base";
interface CreateMusic {
  duration: number;
  name: string;
  artistId: string;
}
interface UpdateMusic {
  artistId: string;
  name: string;
}
class MusicRepositories extends Base<CreateMusic, UpdateMusic> {
  fetchMusic = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createMusic = async (url: string, variables: CreateMusic) => {
    const options = {
      headers: { "content-type": "multipart/form-data" },
    };
    return this.create(url, variables, options);
  };
  updateMusic = async (songId: string, variables: UpdateMusic) => {
    return this.update(`songs/${songId}`, variables);
  };
  deleteMusic = async (songId: number) => {
    return this.delete(`songs/${songId}`);
  };
}

export default new MusicRepositories();
