import Base from "./base";
interface CreateArtist {
  name: string;
}
interface UpdateArtist {
  name: string;
}
class ArtistRepositories extends Base<CreateArtist, UpdateArtist> {
  fetchArtists = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createArtist = async (url: string, variables: CreateArtist) => {
    return this.create(url, variables);
  };
  updateArtist = async (artistsId: number, variables: UpdateArtist) => {
    return this.update(`artists/${artistsId}`, variables);
  };
  deleteArtist = async (artistsId: number) => {
    return this.delete(`artists/${artistsId}`);
  };
}

export default new ArtistRepositories();
