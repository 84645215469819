import { Settings, Trash } from "react-feather";
import Row from "../../../components/ui/Row";
import { IPlaylist } from "../../../types/generalType";
interface CProps {
  deletePlaylist: (playlistId: number) => void;
  selectItem: (item: any) => void;
}

const columns = ({ deletePlaylist, selectItem }: CProps) => [
  {
    Header: "Name",
    accessor: "name", // accessor is the "key" in the data
  },
  {
    Header: "duration (s)",
    accessor: "duration", // accessor is the "key" in the data
  },
  {
    Header: "Genres",
    accessor: "genres.name", // accessor is the "key" in the data

    Cell: ({ row: { original } }: any) => {
      return (
        <div>
          <span className="text-white"> {original?.genres?.[0]?.name} </span>
        </div>
      );
    },
  },
   {
    Header: "Moods",
    accessor: "mouds.name", // accessor is the "key" in the data

    Cell: ({ row: { original } }: any) => {
      return (
        <div>
          <span className="text-white"> {original?.moods?.[0]?.name} </span>
        </div>
      );
    },
  },
  {
    Header: "",
    accessor: "test",
    Cell: ({ row: { original } }: any) => (
      <Row className="space-x-4">
        <div style={{cursor:"pointer"}} onClick={() => deletePlaylist(original?.id)}>
          <Trash color="white" size={20} />
        </div>
        <div
        style={{ cursor: "pointer" }}
        className="" 
        onClick={() => selectItem(original)}>
          <Settings color="white" size={20} />
        </div>
      </Row>
    ),
  },
];
export default columns;
