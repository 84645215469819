import React from "react";
import { Lock } from "react-feather";
import Color from "../../../config/Color";
import { navigationConfig } from "../../../config/navigationConfig";
import { useAuth } from "../../../contexts/AuthContext";
import SidebarItem from "./SidebarItem";

export default function Sidebar() {
  const { logout }: any = useAuth();
  return (
    <div className="shadow">
      <div className="flex justify-center text-2xl items-center my-3">
        <span className="text-primary">ilioa</span>
        <span className="text-white">music</span>
      </div>
      <div className="my-10">
        {navigationConfig?.map((item) => (
          <SidebarItem {...item} key={item?.id} />
        ))}
        <div
          className="flex items-center p-4 cursor-pointer hover:bg-custom-hover"
          onClick={logout}
        >
          <div className="px-4">
            <Lock color={Color.primary} size="32" />
          </div>
          <span className="text-white m-2 text-base font-weight-600 hover:text-white">
            Logout
          </span>
        </div>
      </div>
    </div>
  );
}
