import { ENDPOINTS } from "../../config/constants";
import MoodRepository from "../../repositories/mood";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchMood = async ({ offset = 10, page = 1 }: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await MoodRepository.fetchMood(`${ENDPOINTS.MOODS}?${params}`);
};
export const useMoodsQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.MOODS], () => fetchMood({ offset, page }));
};
