import Base from "./base";
interface CreatePlaylist {
  genre: string;
  name: string;
  mood: string;
}
interface UpdatePlaylist {
  genre: any[];
  name: string;
  mood: any[];
  playlistSongs:any[]
}
interface UpdatePlaylsitsong {
  songsIds: number[];
}
class PlaylistRepositories extends Base<
  CreatePlaylist,
  UpdatePlaylist | UpdatePlaylsitsong
> {
  fetchPlaylist = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createPlaylist = async (url: string, variables: CreatePlaylist) => {
    const options = {
      headers: { "content-type": "multipart/form-data" },
    };
    return this.create(url, variables, options);
  };
  updatePlaylist = async (playlistId: string, variables: any) => {
    return this.update(`playlists/${playlistId}`, variables);
  };
  updatePlaylistSongs = async (playlistId: number, variables: any) => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    return this.update(
      `playlists/${playlistId}/songs`,
      variables as any,
      options
    );
  };
  deletePlaylist = async (playlistId: number) => {
    return this.delete(`playlists/${playlistId}`);
  };
  statistics = async () => {
    return this.http("playlists/statistics", "get");
  };
}

export default new PlaylistRepositories();
