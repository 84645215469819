import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

interface Props {
  color?: string | undefined | null;
  size?: number;
}
export default function Spinner({ color = "black", size = 10 }: Props) {
  return <BeatLoader color={`${color}`} size={size} />;
}
