import React from "react";
interface Props {
  value: number;
}
export default function ProgressBar({ value }: Props) {
  return (
    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
      <div
        className="bg-primary h-1.5 rounded-full"
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
}
