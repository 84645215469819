class AuthError {
  message: string;
  constructor(message: string) {
    this.message = message;
  }
  getMessage() {
    if (this.message?.includes("403")) {
      return "Invalid password";
    }
    if (this.message?.includes("404")) {
      return "Invalid Email address";
    }
    return this.message;
  }
}
export default AuthError;
