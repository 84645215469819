import { ENDPOINTS } from "../config/constants";
import http from "../config/http";
interface AuthProps {
  email: string;
  password: string;
}
class Authentication {
  login = ({ email, password }: AuthProps) => {
    return http.post(ENDPOINTS.AUTH, {
      username: email,
      userPassword: password,
    });
  };
  reset = ({ email }: AuthProps) => {
    return http.post(ENDPOINTS.RESET, {
      userEmail: email,
    });
  };
}
export default new Authentication();
