import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminLayout from "./components/Layout/AdminLayout";
import PublicLayout from "./components/Layout/PublicLayout";
import Artist from "./pages/Artiste";
import Login from "./pages/Authentification/Login";
import Reset from "./pages/Authentification/Reset";
import Buisness from "./pages/Buisness";
import Dashboard from "./pages/Dashboard";
import Folder from "./pages/Folder";
import Genre from "./pages/Genre";
import Moods from "./pages/Moods";
import Music from "./pages/Music";
import PlayList from "./pages/PlayList";

function AppRouter() {
	return (
		<Router>
			<Switch>
				<Route path="/login" exact>
					<PublicLayout>
						<Login />
					</PublicLayout>
				</Route>
				{/* <Route path="/reset" exact>
					<PublicLayout>
						<Reset />
					</PublicLayout>
				</Route> */}

				<AdminLayout>
					<Route path="/" exact>
						<Dashboard />
					</Route>
					<Route path="/music" exact>
						<Music />
					</Route>
					<Route path="/playlist" exact>
						<PlayList />
					</Route>
					<Route path="/playlists-folders" exact>
						<Folder />
					</Route>
					<Route path="/genre" exact>
						<Genre />
					</Route>
					<Route path="/moods" exact>
						<Moods />
					</Route>
					<Route path="/artists" exact>
						<Artist />
					</Route>
					<Route path="/business" exact>
						<Buisness />
					</Route>
				</AdminLayout>
			</Switch>
		</Router>
	);
}

export default AppRouter;
