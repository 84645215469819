import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Navbar from "./Navbar";

import NavBar from "./Navbar";
import Sidebar from "./Sidebar/Sidebar";
interface Props {
  children: any;
}
const AdminLayout: React.FC<Props> = ({ children }: Props) => {
  const { isAuthenticated }: any = useAuth();
  const history = useHistory();
  React.useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) {
        history.push("/login");
      }
    }, 0);
  }, []);
  return (
    <div className="min-h-screen bg-black flex flex-col transition-colors duration-150">
      <div className="flex flex-1">
        <aside className="shadow w-60 xl:w-80 hidden lg:block overflow-y-auto bg-BlackGray pt-22 fixed h-screen">
          <div className="flex flex-col space-y-6 py-3">
            <Sidebar />
          </div>
        </aside>
        <main className="w-full lg:ml-[300px] scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100">
          <div className="lg:hidden md:hidden sm:block">
            <Navbar />
          </div>
          <div className="p-5 md:p-8 overflow-y-auto">{children}</div>
        </main>
      </div>
    </div>
  );
};
export default AdminLayout;
