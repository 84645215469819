const Logo = () => {
  return (
    <div>
      <span className="text-white text-3xl">Ilioa</span>
      <span className="text-primary text-3xl">Music</span>
    </div>
  );
};

export default Logo;
