import Spinner from "../../../components/Spinner"
import Row from "../../../components/ui/Row"
import Color from "../../../config/Color"

interface Props {
  loading: boolean;
  children: any;
}
export const StaticLoader = (props: Props) => {
  return props.loading
    ? (
      <Row className="justify-center">
        <div className="flex lg:mx-2 md:mx-2 center w-auto h-32 pt-8">
          <Spinner color={Color.primary}/>
        </div>
      </Row>
    )
    : (<>{props.children}</>)
}
