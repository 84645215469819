import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { navigationConfig } from "../../../config/navigationConfig";
import { useAppContext } from "../../../contexts/AppContext";

const NavBar = () => {
  const { toggleSidebar, sidebarOpen }: any = useAppContext();
  const history = useHistory();
  function navigateToPath(path: string) {
    history.push(path);
    toggleSidebar();
  }
  const renderItem = ({ title, path }: any) => {
    return (
      <li
        className="cursor-pointer"
        onClick={() => navigateToPath(path)}
        key={path}
      >
        <a className="block text-sm px-2 py-4 hover:bg-primary  text-white transition duration-300 hover:text-black">
          {title}
        </a>
      </li>
    );
  };
  return (
    <header className="bg-black shadow ">
      <nav className="px-5 md:px-8 py-4  items-center justify-end">
        <div className="relative text-gray-600 focus-within:text-gray-400">
          <button
            className="outline-none mobile-menu-button"
            onClick={toggleSidebar}
          >
            <svg
              className="w-6 h-6 text-white"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <div className={classNames(!sidebarOpen && "hidden", "mobile-menu")}>
          <ul className="">{navigationConfig.map((n) => renderItem(n))}</ul>
        </div>
      </nav>
    </header>
  );
};
export default NavBar;
