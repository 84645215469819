import React, { createContext, useContext, useState } from "react";
import auth from "../repositories/auth";
import Token from "../util/Token";

interface Props {
  children: any;
}

export const AuthContext = createContext({});
const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState(null);
  function login(
    values: any,
    history: any,
    onSuccess = () => { },
    onError = (error: any) => { }
  ) {
    return auth
      .login(values)
      .then((httpResponse) => {
        if (httpResponse?.data?.access_token) {
          Token.setToken(httpResponse?.data?.access_token);
          setCurrentUser(httpResponse?.data);
          history.push("/");
          onSuccess();
        }
        onSuccess();
        return httpResponse;
      })
      .catch((httpError: any) => {
        onError(httpError ?? 'Server timeout');
      });
  }
  function logout(history: any) {
    Token.removeToken();
    setTimeout(function () {
      history.push("/login");
    }, 1000);
    window.location.reload();
  }
  const isAuthenticated = React.useMemo(() => {
    return Token.getToken();
  }, [Token.getToken()]);

  function reset(
    values: any,
    history: any,
    onSuccess = () => { },
    onError = (error: any) => { }
  ) {
    return auth
      .reset(values)
      .then((httpResponse) => {
        history.push("/login");
        onSuccess();
        return httpResponse;
      })
      .catch((httpError: any) => {
        onError(httpError ?? 'Server timeout');
      });
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        setCurrentUser,
        login,
        logout,
        reset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
