import React from "react";
import { Link } from "react-router-dom";
interface Props {
  title: string;
  icon?: React.ReactElement;
  path: string;
  onClick?: any;
}
const SidebarItem: React.FC<Props> = ({ title, icon, path }) => {
  return (
    <Link to={path}>
      <div className="flex items-center p-4 cursor-pointer hover:bg-custom-hover">
        <div className="text-xl px-4">{icon && icon}</div>
        <span className="font-['Poppins'] text-white ml-1 text-base font-weight-800 hover:text-white">
          {title}
        </span>
      </div>
    </Link>
  );
};
export default SidebarItem;
