import React from "react";
import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { Genre } from "../../../types/generalType";
import GenreRepositories from "../../../repositories/genre";
import withConfirmation from "../../../hoc/withConfimation";

interface Props {
  data: Genre[];
  showConfirmModal: any;
  refetch: any;
  selectItem: any;
}

const GenreListContainer = ({ data, showConfirmModal, refetch, selectItem }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteGenre(songId: number) {
    showConfirmModal({
      title: "Are you sure to delete this genre ?",
      confirmFunction: () => {
        GenreRepositories.deleteGenre(songId).then(() => {
          refetch();
        });
      },
    });
  }
  return <DataTable columns={columns({ deleteGenre, selectItem })} data={data} />;
};
export default withConfirmation(GenreListContainer);
