import { ENDPOINTS } from "../../config/constants";
import MusicRepositories from "../../repositories/music";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchSongs = async ({ offset = 10, page = 1 }: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await MusicRepositories.fetchMusic(`${ENDPOINTS.SONGS}?${params}`);
};
export const useSongsQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.SONGS], () => fetchSongs({ offset, page }));
};
