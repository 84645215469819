import PaginationRc from "rc-pagination";
import "rc-pagination/assets/index.css";
import "./pagination.css";
interface Props {
  total: number;
  className?: string;
  skip: number;
  onChange: (page: number) => void | undefined;
  limit: number;
}
export default function Pagination({
  total,
  onChange,
  className,
  skip,

  limit,
}: Props) {
  return (
    <PaginationRc
      current={skip}
      total={total}
      onChange={onChange}
      pageSize={limit}
      className={className}
    />
  );
}
