import Base from "./base";
interface CreateMood {
  name: string;
}
interface UpdateMood {
  name: string;
}
class MoodRepositories extends Base<CreateMood, UpdateMood> {
  fetchMood = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createMood = async (url: string, variables: CreateMood) => {
    return this.create(url, variables);
  };
  updateMood = async (moodId: string, variables: UpdateMood) => {
    return this.update(`moods/${moodId}`, variables);
  };
  deleteMood = async (moodId: number) => {
    return this.delete(`moods/${moodId}`);
  };
}

export default new MoodRepositories();
