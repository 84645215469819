import { ENDPOINTS } from "../../config/constants";
import PlaylistRepositories from "../../repositories/playlist";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchPlaylists = async ({
  offset = 10,
  page = 1,
}: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await PlaylistRepositories.fetchPlaylist(
    `${ENDPOINTS.PLAYLISTS}?${params}`
  );
};
export const usePlaylistsQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.PLAYLISTS], () =>
    fetchPlaylists({ offset, page })
  );
};
export const fetchStatistics = async () => {
  return await PlaylistRepositories.statistics();
};
export const usePlaylistsStatisticsQuery = () => {
  return useQuery([ENDPOINTS.PLAYLIST_STATISTIC], fetchStatistics);
};
