class Token {
  token: string | null;
  constructor() {
    this.token = localStorage.getItem("token");
  }
  getToken() {
    return this.token;
  }
  setToken(token: string) {
    localStorage.setItem("token", token);
    this.token = token;
  }
  removeToken() {
    localStorage.removeItem("token");
  }
}
export default new Token();
