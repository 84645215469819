import React from "react";
import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { Buisness } from "../../../types/generalType";
import BuisnessRepositories from "../../../repositories/buisness";
import withConfirmation from "../../../hoc/withConfimation";

interface Props {
  data: Buisness[];
  showConfirmModal: any;
  refetch: any;
  selectItem: any;
}

const BuisnessListContainer = ({ data, showConfirmModal, refetch, selectItem }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteBuisness(buisnessId: number) {
    showConfirmModal({
      title: "Are you sure to delete this buisness ?",
      confirmFunction: () => {
        BuisnessRepositories.deleteBuisness(buisnessId).then(() => {
          refetch();
        });
      },
    });
  }
  return <DataTable columns={columns({ deleteBuisness, selectItem })} data={data} />;
};
export default withConfirmation(BuisnessListContainer);
