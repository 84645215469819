import React from "react";
import { usePlaylistsStatisticsQuery } from "../../data/playlist/playlists.query";
import List from "./components/List";
import StatisticCard from "./components/StatisticCard";
const Dashboard = () => {
  const { data, isLoading } = usePlaylistsStatisticsQuery();
  React.useEffect(() => {
  }, [data, isLoading]);
  return (
    <div>
      <div className="text-white w-full">
        <span className="text-2xl text-white font-bold">Dashboard</span>
      </div>
      <div className="flex flex-wrap space-x-1 w-full justify-between items-center mt-4 space-y-5 lg:space-y-0 md:space-y-0">
        <div className="w-full lg:w-3/12 md:w-3/12">
          <StatisticCard
            title={data?.data?.playlistsCount}
            subTitle="Playlists"
            className="bg-[#E5FFF9]"
          />
        </div>
        <div className="w-full lg:w-3/12 md:w-3/12">
          <StatisticCard
            title={data?.data?.songsCount}
            subTitle="Songs"
            className="bg-[#FFF6E5]"
          />
        </div>
        <div className="lg:w-4/12 w-full md:w-4/12">
          <StatisticCard
            title={data?.data?.songsDuration}
            subTitle="Seconds of music"
            className="bg-[#E5FFE6]"
          />
        </div>
      </div>
      <div className="flex justify-between flex-wrap items-start w-12/12  mt-5">
        <div className="w-full lg:w-6/12">
          <List title="List Genre" data={data?.data?.genresStatistics} />
        </div>
        <div className="w-full lg:w-6/12 ">
          <List title="List Moods" data={data?.data?.moodsStatistics} />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
