import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { Mood } from "../../../types/generalType";
import MoodRepository from "../../../repositories/mood";
import withConfirmation from "../../../hoc/withConfimation";
import React from "react";
interface Props {
  data: Mood[];
  showConfirmModal: any;
  refetch: any;
  selectItem: any;
}
const MoodContainer = ({
  data,
  showConfirmModal,
  refetch,
  selectItem,
}: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteMood(moodId: number) {
    showConfirmModal({
      title: "Are you sure to delete this mood ?",
      confirmFunction: () => {
        MoodRepository.deleteMood(moodId).then(() => {
          refetch();
        });
      },
    });
  }

  return (
    <DataTable columns={columns({ deleteMood, selectItem })} data={data} />
  );
};
export default withConfirmation(MoodContainer);
