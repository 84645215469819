import React from "react";

import { Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../components/Logo";
import { useAuth } from "../../contexts/AuthContext";
import Input from "../../components/Input";
import Button from "../../components/ui/Button";
import AuthError from "../../util/AuthError";

export default function Login() {
  const history = useHistory();
  const { login, isAuthenticated }: any = useAuth();
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="flex flex-col justify-center items-center">
          <div>
            <Logo />
          </div>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validate={(values) => {
              setError(null)
              const errors: any = {};
              if (!values.email) {
                errors.email = "* Email address is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "* Invalid email address";
              }

              if (!values.password) {
                errors.password = "* Password is required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              login(
                values,
                history,
                //onSuccess
                () => setSubmitting(false),
                //onError
                (error: any) => {
                  setSubmitting(false);
                  setError(new AuthError(error.message).getMessage());
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <div>
                <div>
                  <span className="text-white text-2xl font-semibold">
                    Login
                  </span>
                </div>

                <div className="mt-5">
                  <div>
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      maxLength={120}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Email address"
                      inputClassName="rounded-full outline-none border-0"
                      variant="outline"
                    />
                  </div>
                  <div>
                    <Input
                      type="password"
                      name="password"
                      maxLength={120}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Password"
                      inputClassName="rounded-full outline-none border-0"
                      variant="outline"
                    />
                  </div>
                  {/* <div className="flex justify-end cursor-pointer w-[320px] mt-5">
                    <Link className="text-white text-sm" to="/reset">Forgot Password?</Link>
                  </div> */}
                  <div className="w-[320px] mt-2">
                    {errors.email && (
                      <div className="text-red-500">
                        {errors.email && touched.email && errors.email}
                      </div>
                    )}
                    {errors.password && (
                      <div className="text-red-500">
                        {errors.password && touched.password && errors.password}
                      </div>
                    )}
                    {(!errors.email && !errors.password) && error && <div className="text-red-500 "> {error} </div>}
                  </div>

                  <div className="mt-2">
                    <Button
                      className="w-[330px] rounded-full"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
