import Base from "./base";
interface CreateFolder {
  name: string;
}
interface UpdateFolder {
  name: string;
}
class FolderRepositories extends Base<CreateFolder, UpdateFolder> {
  fetchFolder = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createFolder = async (url: string, variables: CreateFolder) => {
    return this.create(url, variables);
  };
  updateFolder = async (folderId: number, variables: UpdateFolder) => {
    return this.update(`playlists-folders/${folderId}`, variables);
  };
  deleteFolder = async (folderId: number) => {
    return this.delete(`playlists-folders/${folderId}`);
  };
}

export default new FolderRepositories();
