import { ENDPOINTS } from "../../config/constants";
import ArtistRepositories from "../../repositories/artist";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchArtists = async ({ offset = 10, page = 1 }: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await ArtistRepositories.fetchArtists(
    `${ENDPOINTS.ARTISTS}?${params}`
  );
};
export const useArtistsQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.ARTISTS], () => fetchArtists({ offset, page }));
};
