import React from "react";
import ModalPlaylist from "../../components/ModalPlaylist";
import PageLoader from "../../components/PageLoader";
import Pagination from "../../components/Pagination";
import Button from "../../components/ui/Button";
import Row from "../../components/ui/Row";
import { usePlaylistsQuery } from "../../data/playlist/playlists.query";
import FormPlaylist from "./components/formPlaylist";
import PlayListContainer from "./components/PlaylistListContainer";
export default function Playlist() {
  const [page, setPage] = React.useState(1);
  const [offset] = React.useState(10);
  const { data, isLoading, refetch }: any = usePlaylistsQuery({ page, offset });
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const handleChangePage = (p: number) => {
    setPage(p);
  };
  React.useEffect(() => {
    refetch();
  }, [page]);
  React.useEffect(() => {}, [isLoading, data]);
  const toogleModal = () => {
    setIsOpenModal(!isOpenModal);
    setSelectedItem(null);
  };
  if (isLoading || !data) return <PageLoader />;
  function selectItem(item: any = null) {
    toogleModal();
    if (item) setSelectedItem(item);
  }
  return (
    <div className="bg-black">
      <div className="p-5">
        <Row className="justify-between items-center">
          <div className="flex flex-col ">
            <span className="text-primary text-2xl">Playlist</span>
            <span className="text-white text-xs">
              {data?.data?.count} playlist
            </span>
          </div>
          <div>
            <Button
              variant="normal"
              size="medium"
              className="rounded-full"
              onClick={toogleModal}
            >
              Add Playlist
            </Button>
          </div>
        </Row>
      </div>
      <div className="">
        <PlayListContainer data={data?.data?.items}
         refetch={refetch} 
         selectItem={selectItem}
         />
        <Row className="justify-end mr-20 mt-5">
          <Pagination
            total={data?.data?.count}
            limit={offset}
            skip={page}
            onChange={handleChangePage}
          />
        </Row>
      </div>
      <ModalPlaylist
        isOpen={isOpenModal}
        toogleModal={toogleModal}
        modalTitle={selectedItem ? "Edit playlist details" : "Add Playlist"}
      >
        <div className="my-4 text-blueGray-500 text-lg leading-relaxed">
          <FormPlaylist 
            toggleModal={toogleModal} 
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            refetch={refetch}
          />
        </div>
      </ModalPlaylist>
    </div>
  );
}
