import React, { useState } from "react";
import { Formik } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Row from "../../../components/ui/Row";
import http from "../../../config/http";
import artist from "../../../repositories/artist";

interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}
export default function FormArtiste({
  selectedItem,
  setSelectedItem,
  toggleModal,
  refetch,
}: Props) {
  const [exist, setExist] = useState<boolean>(false);

  const addArtist = async (payload: any) => {
    try {
      await http.post(`/artists`, payload)
      toggleModal();
      refetch();
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  const updateArtist = async (payload: any) => {
    try {
      await artist.updateArtist(selectedItem?.id, payload)
      toggleModal();
      refetch();
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ name: selectedItem?.name }}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          if (!selectedItem) await addArtist(values);
          else await updateArtist(values);
        }}
        validate={(values) => {
          setExist(false);
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Artist name is required";
          } else if (!/^[A-Za-z0-9]+[A-Za-z0-9\s]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Row>
              <div className="w-full">
                <Input
                  name="name"
                  placeholder="Artist"
                  label="Name"
                  className=""
                  variant="normal"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Row>
            <div className="w-[320px] mt-2">
              {errors.name && (
                <div className="text-red-500">
                  {errors.name && touched.name && errors.name}
                </div>
              )}
            </div>
            {exist && (
              <span className="text-red-500">Artist already exists</span>
            )}
            <div className="flex items-center justify-center pt-6 rounded-b">
              <Button
                type='submit'
                variant="normal"
                className="rounded-full w-40 h-10"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <small className="text-black">Confirm</small>
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
