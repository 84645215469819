// depends on NODE_ENV

import { API_ENDPOINT } from '../.env';
  
const ENDPOINTS = {
  SONGS: "songs",
  GENRES: "genres",
  ARTISTS: "artists",
  BUISNESS:"businesses",
  MOODS: "moods",
  PLAYLISTS: "playlists",
  AUTH: "xauth/signin",
  RESET: "users/resetPassword",
  PLAYLIST_STATISTIC: "statistics",
  FOLDER: "playlists-folders",
};
export { API_ENDPOINT, ENDPOINTS };
