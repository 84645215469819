import axios from "axios";
import Token from "../util/Token";
import { API_ENDPOINT } from "./constants";

const http = axios.create({
  baseURL: API_ENDPOINT, // TODO: take this api URL from env
  timeout: 990000,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      Token.removeToken();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);
// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const token: string | null = Token.getToken();
    config.headers = {
      ...config.headers,
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Change response data/error here
export default http;
