import React from "react";
const AppContext = React.createContext({});
interface Props {
  children: any;
}
const AppProvider = (props: Props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }
  return (
    <AppContext.Provider
      value={{
        data: null,
        sidebarOpen,
        toggleSidebar,
      }}
    >
      {props?.children}
    </AppContext.Provider>
  );
};
const useAppContext = () => React.useContext(AppContext);
export { AppProvider, useAppContext };
