import React from "react";
import cn from "classnames";
interface Props {
  title: string;
  subTitle: string;
  icon?: React.ReactElement;
  color?: string;
  classNameTitle?: string;
  classNameSubTitle?: string;
  className?: string;
}
const StatisticCard: React.FC<Props> = ({
  title,
  subTitle,
  icon,
  color,
  className,
  classNameSubTitle,
  classNameTitle,
}) => {
  return (
    <div className={cn("p-3", "bg-white rounded-md", className)}>
      <div className={cn("text-2xl", classNameSubTitle)}>{title}</div>
      <div className={cn("text-base", classNameSubTitle)}>{subTitle}</div>
    </div>
  );
};
export default StatisticCard;
