import React from "react";
import ProgressBar from "../../../components/ui/ProgressBar";
interface Props {
  title: string;
  data: any;
}
const List: React.FC<Props> = ({ title, data }) => {
  return (
    <div className="">
      <div>
        <span className="text-white text-xl font-bold">{title}</span>
      </div>
      <div className="p-10 border border-gray-500 m-1 rounded-lg space-y-5">
        {data?.map((item: any) => (
          <div className="flex items-center" key={item?.name}>
            <div>
              <span className="text-white text-lg">
                {item?.playlistsPercent}%
              </span>
            </div>
            <div className="ml-3 w-full">
              <span className="text-white text-lg">{item?.name}</span>
              <ProgressBar value={item?.playlistsPercent} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default List;
