import Base from "./base";
interface CreateGenre {
  name: string;
}
interface UpdateGenre {
  name: string;
}
class GenreRepositories extends Base<CreateGenre, UpdateGenre> {
  fetchGenre = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createGenre = async (url: string, variables: CreateGenre) => {
    return this.create(url, variables);
  };
  updateGenre = async (genreId: number, variables: UpdateGenre) => {
    return this.update(`genres/${genreId}`, variables);
  };
  deleteGenre = async (genreId: number) => {
    return this.delete(`genres/${genreId}`);
  };
}

export default new GenreRepositories();
