import Color from "../../config/Color";
import Spinner from "../Spinner";

export default function PageLoader() {
  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner color={Color.primary} size={50} />
    </div>
  );
}
