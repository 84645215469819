import React from "react";
import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { Folder } from "../../../types/generalType";
import FolderRepositories from "../../../repositories/folder";
import withConfirmation from "../../../hoc/withConfimation";

interface Props {
  data: Folder[];
  showConfirmModal: any;
  refetch: any;
  selectItem: any;
}

const FolderListContainer = ({ data, showConfirmModal, refetch, selectItem }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteFolder(songId: number) {
    showConfirmModal({
      title: "Are you sure to delete this foler ?",
      confirmFunction: () => {
        FolderRepositories.deleteFolder(songId).then(() => {
          refetch();
        });
      },
    });
  }
  return <DataTable columns={columns({ deleteFolder, selectItem })} data={data} />;
};
export default withConfirmation(FolderListContainer);
