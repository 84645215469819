import cn from "classnames";
import React, { InputHTMLAttributes } from "react";
import ReactSelect from "react-select";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  label?: string;
  note?: string;
  name: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  variant?: "normal" | "solid" | "outline";
  options: any;
  value?: any;
  defaultValue?: any;
  styles?: any;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  isSearchable?: boolean;
}

const Select = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      note,
      name,
      options,
      defaultValue,
      onChange,
      value,
      styles,
      error,
      children,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      isMulti = false,
      isSearchable = true,
      closeMenuOnSelect = true,
      ...rest
    },
    ref
  ) => {

    return (
      <div className={className}>
        <label
          htmlFor={name}
          className="block text-white font-semibold text-sm leading-none mb-3"
        >
          {label}
        </label>
        <ReactSelect
          id={name}
          name={name}
          options={options}
          value={value}
          closeMenuOnSelect={closeMenuOnSelect}
          defaultValue={defaultValue}
          onChange={onChange}
          className={`${inputClassName} transparent bg-red`}
          styles={{
            ...styles,
          }}
          isSearchable={isSearchable}
          isMulti={isMulti}
          {...rest}
        />
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {error && (
          <p className="my-2 text-xs text-start text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

export default Select;
