import React, { useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Row from "../../../components/ui/Row";
import Dropzoneplaylist from "../../../components/Dropzoneplaylist";
import { useGenresQuery } from "../../../data/genre/genre.query";
import { useMoodsQuery } from "../../../data/mood/mood.query";
import { useSongsQuery } from "../../../data/songs/songs.query";
import { Genre, ISong, Mood } from "../../../types/generalType";
import PlaylistRepositories from "../../../repositories/playlist";
import playlist from "../../../repositories/playlist";
import { GroupBase, StylesConfig } from "react-select";

type ReactSelectItemType = {
  label: string;
  value: string;
};
interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}
interface FormDTO {
  name: string;
  file?: any;
  moods: any[];
  genres: any[];
  songs: any[];
}
export default function FormPlaylist({
  selectedItem,
  setSelectedItem,
  toggleModal,
  refetch,
}: Props) {

  const { data: genreData } = useGenresQuery({ page: 1, offset: 999 });
  const { data: moodsData } = useMoodsQuery({ page: 1, offset: 999 });
  const { data: songData } = useSongsQuery({ page: 1, offset: 999 });

  const renderGenresOptions = (data: any) => {
    return data?.items?.map((genre: Genre) => ({
      label: genre?.name,
      value: genre?.id,
    }));
  };

  const renderSongsOptions = (data: any) => {
    return data?.items?.map((song: ISong) => ({
      label: song?.name,
      value: song?.id,
    }));
  };
  const renderMoodOptions = (data: any) => {
    return data?.items?.map((mood: Mood) => ({
      label: mood?.name,
      value: mood?.id,
    }));
  };

  const addPlayList = async (formDto: FormDTO) => {
    try {
      let formData = new FormData();
      
      if (formDto.file) {
        formData.append("file", new Blob([formDto.file!], { type: "image/jpeg" }));
      }
      
      const playlistSongs = formDto?.songs.map(e => e.value);
      const playlistMoods = formDto?.moods.map(e => e.value);
      const playlistGenres = formDto?.genres.map(e => e.value);

      const payload = {
        name: formDto.name,
        file: formDto.file,
        playlistSongs,
        playlistMoods,
        playlistGenres,
      };

      const data = new Blob([JSON.stringify(payload)], {
        type: "application/json",
      });

      formData.append("playlist", data);
      
      await PlaylistRepositories.createPlaylist(
        "/playlists",
        formData as any
      );
    } catch (error) {
      console.error('Failed to add playlist', error) 
    }
    toggleModal();
    refetch();
  };

  const updatePlaylist = async (formDto: FormDTO) => {
    let formData = new FormData();

    if (formDto.file) {
      formData.append("file", new Blob([formDto.file!], { type: formDto.file!.type }));
    }
    
    const playlistSongs = formDto?.songs.map(e => e.value);
    const playlistMoods = formDto?.moods.map(e => e.value);
    const playlistGenres = formDto?.genres.map(e => e.value);

    const payload = {
      name: formDto.name,
      file: formDto.file,
      playlistSongs,
      playlistMoods,
      playlistGenres,
    };

    const data = new Blob([JSON.stringify(payload)], {
      type: "application/json",
    });
    formData.append("playlist", data);

    await playlist.updatePlaylist(selectedItem?.id, formData)

    toggleModal();
    refetch();

  };
  const getDefaultMoods = () => {
    return selectedItem?.moods?.map((item: Mood) => ({
      label: item.name,
      value: item?.id,
    })) ?? [];
  };
  const getDefaultGenre = () => {
    return selectedItem?.genres?.map((item: Mood) => ({
      label: item.name,
      value: item?.id,
    })) ?? [];
  };
  const getDefaultSongs = () => {
    return selectedItem?.songs?.map((item: ISong) => ({
      label: item.name,
      value: item?.id,
    })) ?? [];
  };
  return (
    <div className="w-full h-96 overflow-auto">
      <Formik
        initialValues={{
          name: selectedItem?.name,
          file: undefined,
          moods: getDefaultMoods(),
          genres: getDefaultGenre(),
          songs: getDefaultSongs()
        }}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          if (!selectedItem) await addPlayList(values);
          else await updatePlaylist(values);
        }}    
        validate={(values) => {
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Name is required";
          } else if (!/^[A-Za-z]+[A-Za-z\s]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          if (!values.songs || values.songs.length === 0 ) {
            errors.songs = 'At least one song is required'
          }
          if (!values.genres || values.genres.length === 0) {
            errors.genres = 'At least one genre is required'
          }
          if (!values.moods || values.moods.length === 0) {
            errors.moods = 'At least one mood is required'
          }
          if (!values.file && !selectedItem) {
            errors.file = 'Cover photo is required'
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <div className="px-4">
            <Row>
              <div className="flex flex inline items-center justify-between w-full">
                <Input
                  name="name"
                  placeholder="Write the name"
                  label="Name"
                  className="my-2 w-full"
                  variant="normal"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Dropzoneplaylist
                  files={(values: any) => setFieldValue('file', values[0])}
                  className="w-64 ml-4 cursor-pointer"
                  title="Add your cover photo"
                />
              </div>
              <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 text-white font-['Poppins']">
                {errors.name && (
                  <p className="text-red-500">
                    {errors.name && touched.name && errors.name}
                  </p>
                )}
                &nbsp;
                {errors.file && (
                  <p className="text-red-500">
                    {errors.file && touched.file && errors.file}
                  </p>
                )}
              </span>
              <div className="w-full">
                <Select
                  label="Song list"
                  options={renderSongsOptions(songData?.data)}
                  styles={customStyles}
                  name="songs"
                  className="bg-BlackGray mt-6 focus:bg-black text-black rounded mt-1 w-full"
                  inputClassName="flex w-full items-center appearance-none transition duration-300 ease-in-out text-heading text-black focus:outline-none focus:ring-0"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={getDefaultSongs()}
                  onChange={(array: any) =>
                    setFieldValue("songs", array)
                  }
                />
              </div>
              <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 text-white font-['Poppins']">
                {errors.songs && (
                  <p className="text-red-500 mt-4">
                    {errors.songs && touched.songs && errors.songs}
                  </p>
                )}
              </span>
              <div className="flex flex inline items-end justify-between w-full">
                <Select
                  label="Genre"
                  name="genres"
                  options={renderGenresOptions(genreData?.data)}
                  styles={customStyles}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={getDefaultGenre()}
                  onChange={(array: any) =>
                    setFieldValue("genres", array)
                  }
                  className="bg-BlackGray mt-6 focus:bg-black text-black rounded mt-1 w-full pr-4"
                  inputClassName="flex w-full items-center appearance-none transition duration-300 ease-in-out text-heading text-black focus:outline-none focus:ring-0"
                />
                <Select
                  label="Mood"
                  name="moods"
                  options={renderMoodOptions(moodsData?.data)}
                  styles={customStyles}
                  className="bg-BlackGray mt-6 focus:bg-black text-black rounded mt-1 w-full pl-4"
                  inputClassName="flex w-full items-center appearance-none transition duration-300 ease-in-out text-heading text-black focus:outline-none focus:ring-0"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={getDefaultMoods()}
                  onChange={(array: any) =>
                    setFieldValue("moods", array)
                  }
                />
              </div>
              <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 text-white font-['Poppins']">
                {errors.genres && (
                  <p className="text-red-500 mt-4">
                    {errors.genres && touched.genres && errors.genres}
                  </p>
                )}
                &nbsp;
                {errors.moods && (
                  <p className="text-red-500 mt-4">
                    {errors.moods && touched.moods && errors.moods}
                  </p>
                )}
              </span>
            </Row>
            <div className="flex items-center justify-center p-6 rounded-b">
              <Button
                type="submit"
                onClick={handleSubmit}
                variant="normal"
                className="rounded-full w-40 h-10"
                loading={isSubmitting}
              >
                <small className="text-black">Confirm</small>
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state?.isFocused ? 'grey' : state.selectProps.backgroundColor,
    color: state.isOptionSelected ? 'white' : undefined,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'white',
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transparent",
    "&:hover": { borderColor: "white" }, // border style on hover
    "&:focus": { borderColor: "white" }, // border style on hover
    border: "none", // default border color
    borderBottom: "1px solid white",
    borderRadius: "0px",
    boxShadow: "none", // no box-shadow
    width: '100%',
    color: state.isOptionSelected ? 'white' : undefined,
  })
};
