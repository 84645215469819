import DataTable from "../../../components/DataTable";
import columns from "./columns";
import { ISong } from "../../../types/generalType";
import MusicRepository from "../../../repositories/music";
import withConfirmation from "../../../hoc/withConfimation";
interface Props {
  data: ISong[];
  showConfirmModal: any;
  refetch:any;
  selectItem: any;
}
const MusicListContainer = ({
   data,
    showConfirmModal, 
    refetch, 
    selectItem }: Props) => {
  if (!data || data?.length === 0) {
    return <span className="text-white">liste vide</span>;
  }
  function deleteSong(songId: number) {
    showConfirmModal({
      title: "Are you sure to delete this song ?",
      confirmFunction: () => {
        MusicRepository.deleteMusic(songId).then(() => {
          refetch();
        });
      },
    });
  }

  return ( 
    <DataTable columns={columns({ deleteSong, selectItem })} data={data} />
  );
};
export default withConfirmation(MusicListContainer);
