import Base from "./base";
interface CreateBuisness {
  name: string;
}
interface UpdateBuisness {
  name: string;
}
class BuisnessRepositories extends Base<CreateBuisness, UpdateBuisness> {
  fetchBuisness = async (url: string, params: string | null = null) => {
    return this.http(url, "get", params);
  };
  createBuisness = async (url: string, variables: CreateBuisness) => {
    return this.create(url, variables);
  };
  updateBuisness = async (businessId: number, variables: UpdateBuisness) => {
    return this.update(`businesses/${businessId}`, variables);
  };
  deleteBuisness = async (businessId: number) => {
    return this.delete(`businesses/${businessId}`);
  };
}

export default new BuisnessRepositories();
