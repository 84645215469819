import React from 'react';
import StyledModal from '@mui/material/Modal';

interface Props {
  isOpen: boolean;
  title?: string;
  children?: React.ReactElement;
  onToggle: () => void;
}
export const SharedModal = ({ title, children, isOpen, onToggle }: Props) => {
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isOpen}
        onClose={onToggle}
      >
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none relative h-full m-auto max-w-3xl ">
          <div className="relative rounded-2xl shadow-lg w-full bg-BlackGray outline-none focus:outline-none border border-solid border-gray-500">
            {!!title && (<div className="flex items-center justify-between p-5 rounded-t">
              <h3 className="text-xl font-semibold text-primary">{title}</h3>
            </div>)}
            <button
              className="absolute p-1 ml-auto top-2 right-4 z-10 pointer text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onToggle}
            >
              <span className="bg-transparent text-white text-3xl h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
            <div className="relative p-6 flex-auto">{children}</div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
}
