import React from "react";
import { useDropzone } from "react-dropzone";
import { UploadCloud } from "react-feather";
import Color from "../../config/Color";
import Button from "../ui/Button";
export default function Dropzone(props: any) {
  const [file, setFile] = React.useState<any>(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    // Do something with the files
    props.files(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop ,accept: 'audio/*'});

  const isButton = !!props.button;
  return (
    <div {...getRootProps()}>
      {isButton ? <>
        <Button
          formTarget=""
          variant="outline"
          className="rounded-full w-40 h-10 hover:bg-primary-hover"
          onClick={() => {
            const dzprops = getInputProps?.()
            if (!dzprops)
              (dzprops as any).onClick();
          }}
        > 
          <small className="text-white">Add more</small>
          <UploadCloud className="ml-2 text-white" width={18}>Add more</UploadCloud>
        </Button>
      </> : (<div className="bg-[#ffffff26]  rounded-md p-[55px] flex justify-center items-center flex-col border border-[#ddd]">
        <input {...getInputProps()} />

        <div className="flex justify-center flex-col items-center">
          <UploadCloud color={Color.primary} size={50} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : file ? (
            <span>{file?.name}</span>
          ) : (
            <span className="text-xs font-bold text-white">
              Drag your files here
            </span>
          )}
        </div>

        <div className="border border-gray-500 p-3 rounded cursor-pointer mt-5 flex justify-center items-center w-40">
          <span className=" text-xs text-white">Browse files</span>
        </div>
      </div>)}
    </div>
  );
}
