import { ENDPOINTS } from "../../config/constants";
import BuisnessRepositories from "../../repositories/buisness";
import { useQuery } from "react-query";
import queryString from "query-string";
import { IParamsQuery } from "../../types/generalType";

export const fetchBuisness = async ({ offset = 10, page = 1 }: IParamsQuery) => {
  const params = queryString.stringify({ offset, page });
  return await BuisnessRepositories.fetchBuisness(
    `${ENDPOINTS.BUISNESS}?${params}`
  );
};
export const useBuisnessQuery = ({ page = 1, offset = 10 }: IParamsQuery) => {
  return useQuery([ENDPOINTS.BUISNESS], () => fetchBuisness({ offset, page }));
};
