import React, { useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/ui/Button";
import Input from "../../../components/Input";
import Row from "../../../components/ui/Row";
import http from "../../../config/http";
import folder from "../../../repositories/folder";
import { usePlaylistsQuery } from "../../../data/playlist/playlists.query";
import { Folder } from "../../../types/generalType";
import Select from "../../../components/Select";
import { GroupBase, StylesConfig } from "react-select";

interface Props {
  selectedItem: any;
  setSelectedItem: any;
  toggleModal: () => void;
  refetch: any;
}
export default function FormFolder({
  selectedItem,
  setSelectedItem,
  toggleModal,
  refetch,
}: Props) {
  const { data: playlistsData } = usePlaylistsQuery({ page: 1, offset: 999 });

  const renderPlaylistsOptions = (data: any) => {
    return data?.items?.map((playlist: any) => ({
      label: playlist?.name,
      value: playlist?.id,
    }));
  };

  const [exist, setExist] = useState<boolean>(false);

  const addFolder = async (payload: any) => {
    try {
      await http.post(`/playlists-folders`, payload)
      toggleModal();
      refetch(); 
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  const updateFolder = async (payload: any) => {
    try {
      await folder.updateFolder(selectedItem?.id, payload)
      toggleModal();
      refetch(); 
      setExist(false)
    } catch (err) {
      setExist(true)
      console.error(err)
    }
  };
  
  const getDefaultPlaylists = () => {
    return selectedItem?.playlists?.map((item: any) => ({
      label: item.name,
      value: item?.id,
    })) ?? [];
  };

  return (
    <div>
      <Formik
        initialValues={{ 
          name: selectedItem?.name,
          playlists: getDefaultPlaylists(), }}
        onSubmit={async (values) => {
          if (!selectedItem) await addFolder(values);
          else await updateFolder(values);
        }}
        validate={(values) => {
          setExist(false)
          const errors: any = {};
          if (!values.name || values.name === '') {
            errors.name = "Folder is required";
          } else if (!/^[A-Za-z]+[A-Za-z\s\-]*$/i.test(values.name)) {
            errors.name = "Please use letters only";
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <Row>
              <div className="w-full">
                <Input
                  name="name"
                  placeholder="Type a Folder"
                  label="Name"
                  className=""
                  variant="normal"
                  maxLength={16}
                  value={values?.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="w-[320px] mt-2">
                  {errors.name && (
                    <div className="text-red-500">
                      {errors.name && touched.name && errors.name}
                    </div>
                  )}
                </div>
                {exist && (
                  <span className="text-red-500">Folder already exists</span>
                )}
              </div>
              <div className="w-full">
                <Select
                  label="playlists list"
                  options={renderPlaylistsOptions(playlistsData?.data)}
                  styles={customStyles}
                  name="playlists"
                  className="bg-BlackGray mt-6 focus:bg-black text-black rounded mt-1 w-full"
                  inputClassName="flex w-full items-center appearance-none transition duration-300 ease-in-out text-heading text-black focus:outline-none focus:ring-0"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={getDefaultPlaylists()}
                  onChange={(array: any) =>
                    setFieldValue("playlistsFolder", array.map((item: any) => item.value))
                  }
                />
              </div>
              <span className="flex inline items-center justify-between w-full text-xs text-center text-red-500 text-white font-['Poppins']">
                {errors.playlists && (
                  <p className="text-red-500 mt-4">
                    {errors.playlists && touched.playlists && errors.playlists}
                  </p>
                )}
              </span>
            </Row>
            <div className="flex items-center justify-center pt-6 rounded-b">
              <Button
                type="submit"
                variant="normal"
                className="rounded-full w-40 h-10"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <small className="text-black">Confirm</small>
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}


const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state?.isFocused ? 'grey' : state.selectProps.backgroundColor,
    color: state.isOptionSelected ? 'white' : undefined,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'white',
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transparent",
    "&:hover": { borderColor: "white" }, // border style on hover
    "&:focus": { borderColor: "white" }, // border style on hover
    border: "none", // default border color
    borderBottom: "1px solid white",
    borderRadius: "0px",
    boxShadow: "none", // no box-shadow
    width: '100%',
    color: state.isOptionSelected ? 'white' : undefined,
  })
};